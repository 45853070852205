import { tmapApi } from './libs/tmap-api';
import { globalVars } from './libs/global-vars';
import { store } from './store';
import { GuardFunction } from 'react-router-guards/dist/types';
import { voiceManager } from './managers';
import { eventBus } from './libs/event-bus';
import { HistoryManagerEvent } from './components/HistoryManager';

/*
주의 : redirect 실행하면 guard 실행 없이 바로 연결됨.
*/

function isSystemError() {
  return !!store.getState().app.systemError;
}

const reset: GuardFunction = (to, from, next) => {
  eventBus.emit(HistoryManagerEvent.CHANGE_START);
  next();
};

const requireUserId: GuardFunction = (to, from, next) => {
  if (isSystemError()) return;
  if (to.meta.auth && !globalVars.mdn) {
    tmapApi.getMdnByAccessKey().then(({ data }) => {
      globalVars.mdn = data.mdn;
      next();
    });
  } else next();
};

const voiceGuard: GuardFunction = (to, from, next) => {
  if (isSystemError()) return;

  // 빨간앱일경우 안내페이지로 이동
  if (to.meta.starVoice) {
    Promise.all([voiceManager.fetchPaymentAgree(), voiceManager.fetchPurchaseList()]).then(() => {
      next();
    });
  } else next();
};

export { reset, requireUserId, voiceGuard };
