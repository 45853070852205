import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, findIndex, union } from 'lodash-es';

import { Footer, Header } from '../../components/Layout';
import { CustomSwiper, SwiperSlide } from '../../components/CustomSwiper';

import { CelebVoiceItem, DefaultVoiceList } from './components';
import { RootState } from '../../store';
import { selectActiveProductList, selectDisabledProductList, selectPurchasedProductList } from '../../store/voice';
import { utils } from '../../libs/utils';
import { voiceManager } from '../../managers';
import { tmapLogbox } from '../../libs/logbox';
import { globalVars } from '../../libs/global-vars';
import {tmapInterface, AppsflyerInterfaceEvent, AppsflyerInterfaceParam} from "../../libs/tmap-interface";

let scrollCache = 0;
let firstInited = false;

function VoiceList() {
  const dispatch = useDispatch();
  const [inited, setInited] = useState(false);
  const [containerVisible, setContainerVisible] = useState(false);
  const voiceState = useSelector((state: RootState) => state.voice);
  const purchasedProductList = useSelector(selectPurchasedProductList);
  const activeProductList = useSelector(selectActiveProductList);
  const disabledProductList = useSelector(selectDisabledProductList);
  const [expiredItemOpen, setExpiredItemOpen] = useState(false);

  useEffect(() => {
    if (firstInited) setInited(true);
    else {
      const fetchList = [voiceManager.fetchBannerList(), voiceManager.fetchProductList(), voiceManager.fetchExpiredProductList()];
      Promise.all(fetchList).then(() => setInited(true));
    }
  }, [dispatch]);

  // 구매한 보이스가 만료되어 사라졌을때 selectedGuideType 유지 되고 있을 경우 기본 보이스로 자동 변경
  useEffect(() => {
    if(inited) {
      const { selectedGuideType, defaultProductList } = voiceState;

      if (findIndex(union(defaultProductList, purchasedProductList), {productId: selectedGuideType}) < 0) {
        voiceManager.setGuideType(defaultProductList[0]);
      }
    }
  }, [inited, voiceState, voiceState.selectedGuideType, purchasedProductList]);

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  useEffect(() => {
    if (inited) {
      const pageid = globalVars.queryData.pageid;
      const hasHashInScheme = pageid && pageid.startsWith('main-sale');

      // 메인 페이지 진입 이벤트 (한번만 호출)
      if(!firstInited) {
        let param;
        if(['female', 'male'].includes(voiceState.selectedGuideType)) {
          param = (voiceState.selectedGuideType === 'female') ? AppsflyerInterfaceParam.DEFAULT_VOICE_FEMALE
              : AppsflyerInterfaceParam.DEFAULT_VOICE_MALE;
        } else param = { af_starvoice_content_id: voiceState.selectedGuideType };
        tmapInterface.recordEvent(AppsflyerInterfaceEvent.INTO_MAIN_VOICE, param);
      }

      if (!firstInited && hasHashInScheme) {
        const targetEl = document.querySelector('#sale_products') as HTMLDivElement;
        if (targetEl) scrollCache = targetEl.offsetTop - 90;
        // ios에서는 image를 가져오기전에 height값이 잡히지 않음. 배너있을경우 고정값으로 추가. (이미지로드 대기시 속도 느려질수 있음.)
        if (globalVars.isIOS) {
          const hasBanner = !isEmpty(voiceState.bannerList)
          const isLandscape = window.orientation === 90 || window.orientation === -90
          if (!isLandscape && hasBanner) scrollCache += 120;
        }
      }
      window.scrollTo(0, scrollCache);
      Promise.resolve().then(() => setContainerVisible(true));
      firstInited = true;
    }
  }, [inited, voiceState.bannerList, voiceState.selectedGuideType]);
  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  // render
  // if (!inited) return null
  return (
    <>
      <Header />
      {inited && (
        <div className="container" style={{ opacity: containerVisible ? 1 : 0 }}>
          <div className="content">
            {/* Banner */}
            {!isEmpty(voiceState.bannerList) && (
              <CustomSwiper>
                {voiceState.bannerList!.map(itemData => (
                  <SwiperSlide key={itemData.adCode}>
                    <button
                      type="button"
                      className="swiper-slide"
                      onClick={() => {
                        // recordEvent
                        tmapInterface.recordEvent(AppsflyerInterfaceEvent.CLICK_BANNER, { ad_code: itemData.adCode })
                        utils.linkToCustomUrl(itemData.linkURL)
                      }}
                      data-logbox-action="tap.ad"
                      data-logbox-custom={JSON.stringify({ ad_code: itemData.adCode })}
                    >
                      <img className="swiper-banner" src={itemData.downloadURL} alt="" />
                    </button>
                  </SwiperSlide>
                ))}
              </CustomSwiper>
            )}

            <section className="voice_type">
              <h1 className="voice_type_title">보이스 선택</h1>

              {/* 구매한상품 */}
              {!isEmpty(purchasedProductList) && (
                <ul className="celeb_voice_list">
                  {purchasedProductList!.map(itemData => (
                    <CelebVoiceItem itemData={itemData} key={itemData.productId} />
                  ))}
                </ul>
              )}

              {/* 기본보이스 */}
              <DefaultVoiceList />
            </section>

            {/* 구매가능상품 */}
            {!isEmpty(activeProductList) && (
              <section className="celeb_voice" id="sale_products">
                <h1 className="celeb_voice_title">셀럽 보이스 구매</h1>
                <ul className="celeb_voice_list">
                  {activeProductList!.map(itemData => (
                    <CelebVoiceItem itemData={itemData} key={itemData.productId} />
                  ))}
                </ul>
              </section>
            )}

            {/* 종료된 보이스 */}
            {!isEmpty(disabledProductList) && (
              <section className="celeb_voice end">
                <h1
                  className={`celeb_voice_title ${expiredItemOpen ? 'unfold' : 'fold'}`}
                  onClick={() => {
                    const value = !expiredItemOpen;
                    setExpiredItemOpen(value);
                    tmapLogbox.sendEvent('tap.end.celeb', { index: value ? '1' : '0' });
                  }}
                >
                  종료된 셀럽 보이스
                </h1>
                {expiredItemOpen && (
                  <ul className="celeb_voice_list">
                    {disabledProductList!.map(itemData => (
                      <li className="celeb_voice_item" key={itemData.productId}>
                        <div className="celeb_voice_thumb">
                          <div className="thumb">
                            <img src={itemData.cover.thumbnailUrl} width="74" className="img" height="74" alt="" />
                          </div>
                        </div>
                        <div className="celeb_voice_info ended">
                          <div className="title">
                            <p className="name">{itemData.name}</p>
                          </div>
                          <p className="desc">{itemData.description}</p>
                          <p className="end">판매종료</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </section>
            )}
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}

export { VoiceList };
